import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../styles/globalStyles';
import SearchIcon from '../assets/icons/search-icon.svg';

const SearchContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;
  position: relative;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px 10px 10px 40px;
  border: 1px solid ${colors.border};
  border-radius: 4px;
  background-color: ${colors.backgroundSecondary};
  color: ${colors.text};
  font-size: 16px;

  &:focus {
    outline: none;
    border-color: ${colors.primary};
    box-shadow: 0 0 0 2px ${colors.primary}33;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.img`
  width: 100%;
  height: 100%;
  opacity: 0.5;
`;

interface SearchBarProps {
  onSearch: (query: string) => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
    onSearch(query);
  };

  return (
    <SearchContainer>
      <IconWrapper>
        <Icon src={SearchIcon} alt="Search" />
      </IconWrapper>
      <SearchInput
        type="text"
        placeholder="Search stories..."
        value={searchQuery}
        onChange={handleSearch}
      />
    </SearchContainer>
  );
};
