import React from 'react';
import styled from 'styled-components';
import Footer from '../components/Footer';
import AccountMenu from '../components/AccountMenu';

const isProd = process.env.REACT_APP_ENV === 'production';

interface LayoutProps {
    children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <Wrapper>
            <Header>
                {!isProd && <AccountMenu />}
            </Header>

            <Main>
                {children}
            </Main>

            <FooterContainer>
                <Footer />
            </FooterContainer>
        </Wrapper>
    );
};

// Styled Components
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh; /* Фиксированная высота */
`;

const Header = styled.header`
    flex-shrink: 0;
`;

const Main = styled.main`
    flex: 1; /* Занимает все доступное пространство между header и footer */
    min-height: 0; /* Важно для корректной работы flex */
`;

const FooterContainer = styled.footer`
    flex-shrink: 0;
`;

export default Layout;
