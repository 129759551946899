import { create } from 'zustand';

interface RelationshipScaleState {
  level: number;
  progress: number;
  increaseProgress: (amount: number) => void;
}

export const useRelationshipScaleStore = create<RelationshipScaleState>((set) => ({
  level: 0,
  progress: 0,
  increaseProgress: (amount) =>
    set((state) => {
      const newProgress = state.progress + amount;
      const newLevel = Math.floor(newProgress / 100);
      return {
        progress: newProgress,
        level: newLevel,
      };
    }),
}));
