import React, { useState } from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AdminPanel from './AdminPanel';
import { useNavigate } from 'react-router-dom';
import StatusIndicator from './StatusIndicator';
import styled from 'styled-components';

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: 10px 20px;
`;

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    color: inherit;
  }
`;

const AccountMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isAdminOpen, setIsAdminOpen] = useState(false);

  const navigate = useNavigate();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAdminOpen = () => {
    setIsAdminOpen(true);
    handleMenuClose();
  };

  return (
    <MenuContainer>
      <StyledIconButton
        edge="end"
        onClick={handleMenuOpen}
      >
        <AccountCircleIcon />
      </StyledIconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleAdminOpen}>Admin panel</MenuItem>
        {/* <MenuItem onClick={() => navigate('/stars')}>My account</MenuItem> */}
        <MenuItem onClick={handleMenuClose}>Logout</MenuItem>
      </Menu>

      <AdminPanel 
        open={isAdminOpen} 
        onClose={() => setIsAdminOpen(false)} 
      />
    </MenuContainer>
  );
};

export default AccountMenu;
