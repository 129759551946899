// webSocketStore.ts
import { create } from 'zustand';
import { SendMessage, Relationship, Location, MovementState } from '../types';
import { useMessageStore } from '../store/messageStore';
import { QueryClient } from '@tanstack/react-query';
import { useNotificationStore } from '../store/notificationStore';
import { useLocationStore } from '../store/locationStore';
import { Message } from '../types/message';

interface WebSocketStore {
    socket: WebSocket | null;
    isConnected: boolean;
    sendMessage: (message: SendMessage) => void;
    connect: (url: string, queryClient: QueryClient) => void;
    disconnect: () => void;
}

export const useWebSocketStore = create<WebSocketStore>((set, get) => ({
    socket: null,
    isConnected: false,

    connect: (url, queryClient) => {
        console.log('connect');
        if (get().socket) {
            return;
        }

        const token = localStorage.getItem('token') || '';
        const socketUrl = `${url}`;
        const socket = new WebSocket(socketUrl);
        const showToast = useNotificationStore.getState().showToast;

        const { addMessage } = useMessageStore.getState();

        socket.onopen = () => {
            set({ isConnected: true, socket });
            get().sendMessage({
                type: "auth",
                content: { token }
            });
            console.log('WebSocket connection opened');
        };

        socket.onmessage = ({ data }) => {
            const newMessage = JSON.parse(data);
            const { setCurrentLocation, setMovementState } = useLocationStore.getState();

            if (newMessage.type === 'pre_character_message') {
                useMessageStore.getState().setIsTyping(true);
                setTimeout(() => {
                    useMessageStore.getState().setIsTyping(false);
                }, 5000);
            }

            if (newMessage.type === 'character_message') {
                const currentMessages = queryClient.getQueryData<Message[]>(['messages']) || [];
                const isDuplicate = currentMessages.some(msg => msg.id === newMessage.content.id);
                useMessageStore.getState().setIsTyping(false);
                if (!isDuplicate) {
                    console.log('newMessage', newMessage);
                    addMessage(newMessage);
                    queryClient.setQueryData(['messages'], (oldMessages: Message[] = []) =>
                        [...oldMessages, newMessage.content]
                    );

                    const messageContent = newMessage.content.content;
                    showToast(`📜 New message received: "${messageContent}"`, 'success');
                }
            }

            if (newMessage.type === 'relationship_update') {
                queryClient.setQueryData(['currentRelationship'], newMessage.content as Relationship);
                showToast('✨ Character relationship has changed!', 'info');
            }

            if (newMessage.type === 'location_update') {
                setCurrentLocation(newMessage.content as Location);
                showToast(`📍 You have arrived at location: ${newMessage.content.name}`, 'info');
            }

            if (newMessage.type === 'movement_state_update') {
                setMovementState(newMessage.content as MovementState);

                const stateMessages = {
                    moving: '🚶 Moving...',
                    arrived: '✨ Arrived!',
                    idle: '🏡 Stopped'
                };

                showToast(stateMessages[newMessage.content as MovementState], 'info');
            }
        };

        socket.onclose = () => {
            set({ isConnected: false, socket: null });
            showToast('🔮 Connection to the world lost. Attempting to reconnect...', 'warning');
            setTimeout(() => get().connect(url, queryClient), 3000);
            console.log('WebSocket connection closed');
        };

        socket.onerror = (error) => {
            console.error('WebSocket Error:', error);
            showToast('⚠️ A magical error occurred during connection', 'error');
            console.log('WebSocket Error:', error);
        };

        set({ socket });
    },

    sendMessage: (message) => {
        const { socket, isConnected } = get();
        if (socket && isConnected) {
            socket.send(JSON.stringify(message));
        }
    },

    disconnect: () => {
        const { socket } = get();
        if (socket) {
            socket.close();
            set({ socket: null, isConnected: false });
        }
    }
}));
