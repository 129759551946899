import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { colors } from '../styles/globalStyles';
import { completeStory } from '../services/API';

const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContent = styled.div`
  background-color: ${colors.backgroundSecondary};
  padding: 20px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  text-align: center;
`;

const Button = styled.button`
  background-color: ${colors.primary};
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;

  &:hover {
    background-color: #0056b3;
  }
`;

interface EndStoryPopupProps {
  onClose: () => void;
  storyId: number;
}

export const EndStoryPopup: React.FC<EndStoryPopupProps> = ({ onClose, storyId }) => {
  const navigate = useNavigate();

  const handleReturnToList = () => {
    onClose();
    completeStory(storyId);
    navigate('/story-list');
  };

  return (
    <PopupContainer>
      <PopupContent>
        <h2>Congratulations! You've completed the story.</h2>
        <p>It was exciting, wasn't it?</p>
        <Button onClick={handleReturnToList}>Return to Story List</Button>
      </PopupContent>
    </PopupContainer>
  );
};
