import styled from 'styled-components';
import MessageList from '../components/MessageList';
import MessageInput from '../components/MessageInput';
import CharacterTab from '../components/CharacterTab';
import Layout from './Layout';
import mainBg from '../assets/images/bg-main.png';
import chatBg from '../assets/images/bg-chat.svg';
import pastor from '../assets/images/pastor.png';
import { colors } from '../styles/globalStyles';

const isProd = process.env.REACT_APP_ENV === 'production';
const ChatPage = () => {

    return (
        <Layout>
            <Wrapper>
                <ChatContainer>
                    <BackgroundImage src={chatBg} alt="chat background" />
                    <PastorImage src={pastor} alt="pastor" />
                    {!isProd && (
                        <CharacterTab />
                    )}
                    <StyledMessageList />
                    <StyledMessageInput />
                </ChatContainer>

            </Wrapper>
        </Layout>
    );
};


const Wrapper = styled.div`
    background-image: url(${mainBg});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #0A0A0A;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 10px;
`;

const ChatContainer = styled.div`
    background: radial-gradient(224.3% 224.3% at 50% 224.3%, rgba(255, 214, 126, 0.75) 0%, rgba(19, 19, 19, 0.75) 59.44%, rgba(10, 10, 10, 0.75) 73.42%, rgba(10, 10, 10, 0.75) 100%);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 96%;
  padding: 10px;
  justify-content: flex-end;
  gap: 12px;
  overflow: hidden;
  z-index: 3;

    border: 1px solid ${colors.primary};



`;

const BackgroundImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    pointer-events: none;
`;

const PastorImage = styled.img`
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    height: 90%;
    width: auto;
    object-fit: contain;
    z-index: 1;
    pointer-events: none;

    // @media (max-width: 768px) {
    //     height: 70%;
    // }

    // @media (max-width: 480px) {
    //     height: 50%;
    // }
`;

const StyledMessageList = styled(MessageList)`
    flex: 1;
    overflow-y: auto;
    min-height: 0;
    position: relative;
    z-index: 3;
`;

const StyledMessageInput = styled(MessageInput)`
    flex-shrink: 0;
    position: relative;
    z-index: 3;
`;

export default ChatPage;