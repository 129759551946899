import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useStoryStore } from '../store/storyStore';
import { config } from '../config';
import { StoryDescription } from './StoryDescription';
import { EndStoryPopup } from '../components/EndStoryPopup';
import { colors } from '../styles/globalStyles';
import { Loader } from './Loader';

const getFullMediaUrl = (src: string): string => {
    return src.startsWith('/media/') ? `${config.BACKEND_URL}${src}` : src;
};

export const Slideshow: React.FC = () => {
    const { currentStory } = useStoryStore();
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [showEndPopup, setShowEndPopup] = useState(false);
    const [isTypingComplete, setIsTypingComplete] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [loadedSlides, setLoadedSlides] = useState<Set<number>>(new Set());
    const videoRef = useRef<HTMLVideoElement>(null);

    const trackImageLoad = (url: string, slideIndex: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            const img = document.createElement('img');
            img.onload = () => {
                console.log('Изображение загружено:', url);
                setLoadedSlides(prev => new Set(prev).add(slideIndex));
                resolve();
            };
            img.onerror = () => {
                console.error('Ошибка при загрузке изображения:', url);
                reject();
            };
            img.src = getFullMediaUrl(url);
        });
    };

    const trackVideoLoad = (url: string, slideIndex: number): Promise<void> => {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            video.onloadeddata = () => {
                console.log('Видео загружено:', url);
                setLoadedSlides(prev => new Set(prev).add(slideIndex));
                resolve();
            };
            video.onerror = () => {
                console.error('Ошибка при загрузке видео:', url);
                reject();
            };
            video.src = getFullMediaUrl(url);
            video.preload = 'auto';
        });
    };

    const loadSlide = async (index: number) => {
        if (!currentStory || loadedSlides.has(index)) return;

        const slide = currentStory.slides[index];
        if (!slide) return;

        setIsLoading(true);
        try {
            const isVideo = slide.visual.src.endsWith('.mp4');
            await (isVideo ? 
                trackVideoLoad(slide.visual.src, index) : 
                trackImageLoad(slide.visual.src, index)
            );
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (currentStory) {
            loadSlide(currentSlideIndex);
        }
    }, [currentStory, currentSlideIndex]);

    useEffect(() => {
        if (currentStory && !isLoading && loadedSlides.has(currentSlideIndex)) {
            const nextIndex = currentSlideIndex + 1;
            if (nextIndex < currentStory.slides.length) {
                loadSlide(nextIndex);
            }
        }
    }, [currentStory, currentSlideIndex, isLoading, loadedSlides]);

    if (!currentStory) {
        return <Loader text="Loading story..." />;
    }

    if (isLoading && !loadedSlides.has(currentSlideIndex)) {
        return <Loader text="Loading slide..." />;
    }

    const currentSlide = currentStory.slides[currentSlideIndex];
    const isVideo = currentSlide?.visual.src.endsWith('.mp4');
    const mediaUrl = currentSlide?.visual.src;

    const handleNext = () => {
        if (isTypingComplete) {
            if (currentSlideIndex < currentStory.slides.length - 1) {
                setCurrentSlideIndex(prevIndex => prevIndex + 1);
                setIsTypingComplete(false);
            } else {
                setShowEndPopup(true);
            }
        } else {
            setIsTypingComplete(true);
        }
    };

    const handlePrevious = () => {
        setCurrentSlideIndex(prevIndex => Math.max(prevIndex - 1, 0));
    };

    return (
        <SlideshowContainer>
            <StoryContainer>
                <MediaContainer>
                    {isVideo ? (
                        <Video
                            ref={videoRef}
                            src={getFullMediaUrl(mediaUrl)}
                            autoPlay
                            loop
                            muted
                            playsInline
                        />
                    ) : (
                        <Image src={getFullMediaUrl(mediaUrl)} alt="Story" />
                    )}
                </MediaContainer>
                <NavigationOverlay>
                    <NavigationButton onClick={handlePrevious} />
                    <NavigationButton onClick={handleNext} />
                </NavigationOverlay>
            </StoryContainer>
            <DescriptionContainer>
                <StoryDescription
                    text={currentSlide?.text}
                    isTypingComplete={isTypingComplete}
                    setIsTypingComplete={setIsTypingComplete}
                />
            </DescriptionContainer>
            {showEndPopup && <EndStoryPopup onClose={() => setShowEndPopup(false)} storyId={currentStory.id} />}
        </SlideshowContainer>
    );
};

// Styled Components
const SlideshowContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const StoryContainer = styled.div`
  flex: 3;
  width: 100%;
  position: relative;
`;

const MediaContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const Video = styled.video`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const NavigationOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
`;

const NavigationButton = styled.div`
  flex: 1;
  cursor: pointer;
`;

const DescriptionContainer = styled.div`
  flex: 1;
  background-color: ${colors.background};
  width: 100%;
  padding: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
`;
