import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useNotificationStore } from '../store/notificationStore';
import { useWebSocketStore } from '../store/webSocketStore';
import { config } from '../config';
import { useQueryClient } from '@tanstack/react-query';

const SecureRoute: React.FC = () => {
  console.log('SecureRoute');
  
  const navigate = useNavigate();
  const setNavigationCallback = useNotificationStore(state => state.setNavigationCallback);

  useEffect(() => {
    setNavigationCallback(navigate);
  }, [navigate, setNavigationCallback]);

  const token = localStorage.getItem('token');
  const queryClient = useQueryClient();

  const connectWebSocket = useWebSocketStore((state) => state.connect);
  const disconnectWebSocket = useWebSocketStore((state) => state.disconnect);
  const setCurrentPath = useNotificationStore((state) => state.setCurrentPath);
  const location = useLocation();

  // Обновляем текущий путь в Zustand-хранилище при каждом изменении маршрута
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname, setCurrentPath]);

  useEffect(() => {
    if (token) { // Проверяем наличие токена перед подключением
      const webSocketUrl = `${config.CHAT_WS_URL}/messages/ws`;
      connectWebSocket(webSocketUrl, queryClient);
      
      return () => {
        disconnectWebSocket();
      };
    }
  }, [connectWebSocket, disconnectWebSocket, queryClient, token]);

  if (!token) {
    return <Navigate to="/login" replace />;
  }
 
  return <Outlet />;
};

export default SecureRoute;