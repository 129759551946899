import React, { useState } from 'react';
import { Button, Stack, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { CreateStory } from './CreateStory';
import { useRelationshipScaleStore } from '../store/relationshipScaleStore';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getRelationships, getCurrentRelationship, setRelationshipStatus, deleteAllMessages, getMessages } from '../services/API';

interface AdminPanelProps {
  open: boolean;
  onClose: () => void;
}
console.log(process.env.REACT_APP_ENV);

const isProd = process.env.REACT_APP_ENV === 'production';
console.log('isProd',isProd);


const AdminPanel: React.FC<AdminPanelProps> = ({ open, onClose }) => {
  const [isCreateStoryOpen, setCreateStoryOpen] = useState(false);
  const [showDescriptions, setShowDescriptions] = useState(false);
  const [content, setContent] = useState<JSX.Element[]>([]);
  const { increaseProgress } = useRelationshipScaleStore();
  const queryClient = useQueryClient();

  const { data: lvlDescriptions = [], error: lvlError } = useQuery({ queryKey: ['relationships'], queryFn: getRelationships });
  const { data: currentRelationship, error: currentError } = useQuery({ queryKey: ['currentRelationship'], queryFn: getCurrentRelationship });
  const { data: messages = [] } = useQuery({ 
    queryKey: ['messages'], 
    queryFn: getMessages 
  });

  const setStatusMutation = useMutation({
    mutationFn: setRelationshipStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['currentRelationship'] });
    },
  });

  const handleCreateStory = () => setCreateStoryOpen(true);
  const handleCreateStoryClose = () => setCreateStoryOpen(false);

  const handleShowDescriptions = () => {
    setShowDescriptions(true);
    setContent(lvlDescriptions.map((desc) => (
      <div key={desc.id} style={{ marginBottom: '10px', padding: '10px' }}>
        <h3 style={{ margin: '0', fontSize: '1.2em' }}>{desc.name}</h3>
        <p style={{ margin: '5px 0', fontSize: '0.9em' }}>{desc.description}</p>
        <p style={{ margin: '5px 0', fontSize: '0.9em' }}>Points needed: {desc.required_points}</p>
        <Button
          variant="contained"
          color="success"
          onClick={() => setStatusMutation.mutate(desc.id)}
        >
          Set as Current
        </Button>
      </div>
    )));
  };

  const handleClearContent = () => {
    setContent([]);
    setShowDescriptions(false);
  };

  const handleDeleteAllMessages = async () => {
    try {
      await deleteAllMessages();
      queryClient.invalidateQueries({ queryKey: ['messages'] });
      alert('All messages have been deleted');
      window.location.reload();
    } catch (error) {
      console.error('Error deleting messages:', error);
      alert('Failed to delete messages');
    }
  };

  interface Message {
    role: string;
    content: string;
  }

  const handleCopyMessages = async () => {
    if (!messages) return;
    
    const formattedMessages = (messages as Message[])
      .map(msg => {
        let role = '';
        switch (msg.role) {
          case 'PLAYER':
            role = 'User';
            break;
          case 'CHARACTER':
            role = 'Assistant';
            break;
          case 'SYSTEM':
            role = 'System';
            break;
          default:
            role = msg.role;
        }
        return `${role}: ${msg.content}`;
      })
      .join('\n\n');
    
    try {
      await navigator.clipboard.writeText(formattedMessages);
      alert('Message history copied to clipboard');
    } catch (error) {
      console.error('Error copying messages:', error);
      alert('Failed to copy message history');
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="md" 
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: '#1E1E1E', // colors.background
          color: '#E0E0E0'           // colors.text
        }
      }}
    >
      <DialogTitle>Admin Panel</DialogTitle>
      <DialogContent>
        <Stack gap={2} direction="row" flexWrap="wrap" sx={{ mb: 2 }}>
          {!isProd && (
            <Button 
              variant="contained" 
              sx={{ backgroundColor: '#4CAF50' }} // colors.success
              onClick={handleCreateStory}
            >
              Create Story
            </Button>
          )}
          <Button 
            variant="contained" 
            sx={{ backgroundColor: '#7289DA' }} // colors.primary
            onClick={handleShowDescriptions}
          >
            Get level description
          </Button>
          <Button 
            variant="contained" 
            sx={{ backgroundColor: '#EF5350' }} // colors.error
            onClick={handleDeleteAllMessages}
          >
            Delete All Messages
          </Button>
          <Button 
            variant="contained" 
            sx={{ backgroundColor: '#B39DDB' }} // colors.secondary
            onClick={handleCopyMessages}
          >
            Copy History
          </Button>
        </Stack>
        
        <div style={{ color: '#E0E0E0' }}> {/* colors.text */}
          <h2>Current Level: {currentRelationship ? currentRelationship.current_status.name : 'Loading...'}</h2>
          <p>Points: {currentRelationship ? currentRelationship.current_points : 'Loading...'}</p>
          <p>Points needed for next level: {currentRelationship ? currentRelationship.current_status.required_points : 'Loading...'}</p>
          {showDescriptions && content}
        </div>
        
        <CreateStory open={isCreateStoryOpen} onClose={handleCreateStoryClose} />
      </DialogContent>
    </Dialog>
  );
};

export default AdminPanel; 