import {create} from 'zustand';

interface Visual {
  name: string;
  src: string;
  repeat?: boolean;
}

interface Slide {
  visual: Visual;
  text: string;
}

export interface Story {
  id: number;
  title: string;
  description: string;
  slides: Slide[];
  created: string;
  creation_sec: number;
  viewed: boolean;
}

const sampleStories: Story[] = [
  {
    id: 1,
    title: "Приключения в Зачарованном лесу",
    description: "Исследуйте таинственный лес, полный магии и загадок.",
    slides: [
      { visual: { name: "forest", src: "forest.jpg" }, text: "Вы входите в густой, туманный лес..." },
      { visual: { name: "fairy", src: "fairy.jpg" }, text: "Внезапно перед вами появляется светящаяся фея!" },
    ],
    created: "2023-05-01",
    creation_sec: 1620000000,
    viewed: false,
  },
  {
    id: 2,
    title: "Тайна Древнего Храма",
    description: "Раскройте секреты забытой цивилизации в руинах древнего храма.",
    slides: [
      { visual: { name: "temple", src: "temple.jpg" }, text: "Перед вами возвышаются величественные руины храма..." },
      { visual: { name: "artifact", src: "artifact.jpg" }, text: "В центре зала вы замечаете странный светящийся артефакт." },
    ],
    created: "2023-05-15",
    creation_sec: 1621000000,
    viewed: false,
  },
  {
    id: 3,
    title: "Путешествие на Луну",
    description: "Отправьтесь в космическое приключение и исследуйте поверхность Луны.",
    slides: [
      { visual: { name: "moon", src: "moon.jpg" }, text: "Вы приземляетесь на поверхность Луны..." },
      { visual: { name: "rover", src: "rover.jpg" }, text: "Вы начинаете исследовать лунный ландшафт на ровере." },
    ],
    created: "2023-06-01",
    creation_sec: 1622000000,
    viewed: false,
  },
  {
    id: 4,
    title: "Загадка Подводного Города",
    description: "Исследуйте затонувший город и раскройте его тайны.",
    slides: [
      { visual: { name: "underwater_city", src: "underwater_city.jpg" }, text: "Вы погружаетесь в глубины океана..." },
      { visual: { name: "treasure", src: "treasure.jpg" }, text: "Вы находите древний сундук с сокровищами." },
    ],
    created: "2023-06-15",
    creation_sec: 1623000000,
    viewed: false,
  },
  {
    id: 5,
    title: "Потерянный Мир Динозавров",
    description: "Путешествуйте во времени и исследуйте мир динозавров.",
    slides: [
      { visual: { name: "dinosaurs", src: "dinosaurs.jpg" }, text: "Вы оказываетесь в мире, полном динозавров..." },
      { visual: { name: "volcano", src: "volcano.jpg" }, text: "Вдали виднеется извергающийся вулкан." },
    ],
    created: "2023-07-01",
    creation_sec: 1624000000,
    viewed: false,
  },
  {
    id: 6,
    title: "Тайны Древнего Египта",
    description: "Раскройте секреты пирамид и фараонов.",
    slides: [
      { visual: { name: "pyramids", src: "pyramids.jpg" }, text: "Вы стоите перед величественными пирамидами..." },
      { visual: { name: "sphinx", src: "sphinx.jpg" }, text: "Вы видите загадочного Сфинкса." },
    ],
    created: "2023-07-15",
    creation_sec: 1625000000,
    viewed: false,
  },
  {
    id: 7,
    title: "Путешествие в Будущее",
    description: "Отправьтесь в будущее и узнайте, каким станет мир.",
    slides: [
      { visual: { name: "future_city", src: "future_city.jpg" }, text: "Вы оказываетесь в футуристическом городе..." },
      { visual: { name: "flying_cars", src: "flying_cars.jpg" }, text: "Вы видите летающие автомобили и высокие небоскребы." },
    ],
    created: "2023-08-01",
    creation_sec: 1626000000,
    viewed: false,
  },
  {
    id: 8,
    title: "Тайна Затерянного Острова",
    description: "Исследуйте таинственный остров, полный загадок и приключений.",
    slides: [
      { visual: { name: "island", src: "island.jpg" }, text: "Вы высаживаетесь на берег таинственного острова..." },
      { visual: { name: "cave", src: "cave.jpg" }, text: "Вы находите вход в темную пещеру." },
    ],
    created: "2023-08-15",
    creation_sec: 1627000000,
    viewed: false,
  },
  {
    id: 9,
    title: "Путешествие в Средневековье",
    description: "Отправьтесь в прошлое и узнайте, как жили люди в Средневековье.",
    slides: [
      { visual: { name: "castle", src: "castle.jpg" }, text: "Вы видите величественный замок на холме..." },
      { visual: { name: "knight", src: "knight.jpg" }, text: "Вы встречаете рыцаря в блестящих доспехах." },
    ],
    created: "2023-09-01",
    creation_sec: 1628000000,
    viewed: false,
  },
  {
    id: 10,
    title: "Тайна Лабиринта",
    description: "Найдите выход из запутанного лабиринта.",
    slides: [
      { visual: { name: "labyrinth", src: "labyrinth.jpg" }, text: "Вы входите в запутанный лабиринт..." },
      { visual: { name: "minotaur", src: "minotaur.jpg" }, text: "Вы слышите рык мифического минотавра." },
    ],
    created: "2023-09-15",
    creation_sec: 1629000000,
    viewed: false,
  },
];

interface StoryStore {
  stories: Story[];
  currentStoryIndex: number;
  currentStory: Story | null;
  currentSlideIndex: number;
  nextSlide: () => void;
  previousSlide: () => void;
  setCurrentStory: (id: number) => void;
  addStory: (story: Omit<Story, 'id'>) => void;
  addStories: (stories: Story[]) => void;
}

export const useStoryStore = create<StoryStore>((set, get) => ({
  stories: sampleStories, // Инициализируем stories с пробными данными
  currentStoryIndex: 0,
  currentStory: null,
  currentSlideIndex: 0,
  nextSlide: () => {
    const { currentStory, currentSlideIndex } = get();
    if (currentStory) {
      const nextIndex = (currentSlideIndex + 1) % currentStory.slides.length;
      set({ currentSlideIndex: nextIndex });
    }
  },
  previousSlide: () => {
    const { currentStory, currentSlideIndex } = get();
    if (currentStory) {
      const prevIndex = (currentSlideIndex - 1 + currentStory.slides.length) % currentStory.slides.length;
      set({ currentSlideIndex: prevIndex });
    }
  },
  setCurrentStory: (id: number) => {
    const story = get().stories.find(s => s.id === id);
    if (story) {
      const index = get().stories.indexOf(story);
      set({ currentStoryIndex: index, currentStory: story, currentSlideIndex: 0 });
    }
  },
  addStory: (story: Omit<Story, 'id'>) => {
    set((state) => {
      const newId = Math.max(...state.stories.map(s => s.id), 0) + 1;
      const newStory = { ...story, id: newId };
      return { stories: [...state.stories, newStory] };
    });
  },
  addStories: (stories: Story[]) => {
    set({ stories });
  },
}));

// Удалите или закомментируйте эту строку, так как теперь у нас есть начальные данные
// useStoryStore.getState().currentStory = useStoryStore.getState().stories[0];
