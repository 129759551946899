import { create } from 'zustand';
import { Location, MovementState } from '../types';

interface LocationStore {
  currentLocation: Location | null;
  movementState: MovementState;
  previousLocation: Location | null;
  setCurrentLocation: (location: Location) => void;
  setMovementState: (state: MovementState) => void;
  setPreviousLocation: (location: Location) => void;
}

export const useLocationStore = create<LocationStore>((set) => ({
  currentLocation: null,
  movementState: 'idle',
  previousLocation: null,

  setCurrentLocation: (location) => 
    set((state) => ({
      currentLocation: location,
      previousLocation: state.currentLocation
    })),

  setMovementState: (state) => 
    set(() => ({
      movementState: state
    })),

  setPreviousLocation: (location) =>
    set(() => ({
      previousLocation: location
    }))
})); 