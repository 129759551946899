// notificationStore.ts
import {create} from 'zustand';
import { toast, ToastOptions } from 'react-toastify';

// Интерфейс для Zustand-хранилища уведомлений
interface NotificationStore {
    excludedPath: string;
    currentPath: string;
    navigationCallback: ((path: string) => void) | null;
    setCurrentPath: (path: string) => void;
    setNavigationCallback: (callback: (path: string) => void) => void;
    showToast: (message: string, type?: 'info' | 'success' | 'error' | 'warning') => void;
}

// Создаем Zustand хранилище с состоянием пути и методом showToast
export const useNotificationStore = create<NotificationStore>((set, get) => ({
    excludedPath: '/chat', // Путь, на котором уведомления не будут показываться
    currentPath: '',
    navigationCallback: null,

    // Метод для обновления текущего пути
    setCurrentPath: (path) => set({ currentPath: path }),

    // Метод для установки callback-функции для навигации
    setNavigationCallback: (callback) => set({ navigationCallback: callback }),

    // Метод для показа уведомления с проверкой текущего пути
    showToast: (message, type = 'info') => {
        const store = get();
        if (store.currentPath !== store.excludedPath) {
            const options: ToastOptions = {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                onClick: () => {
                    if (store.navigationCallback) {
                        store.navigationCallback('/chat');
                    }
                }
            };

            switch (type) {
                case 'success':
                    toast.success(message, options);
                    break;
                case 'error':
                    toast.error(message, options);
                    break;
                case 'warning':
                    toast.warning(message, options);
                    break;
                default:
                    toast.info(message, options);
            }
        }
    },
}));