import {create} from 'zustand';
import { Message } from '../types/message';

interface Relationship {
  current_points: number;
  current_status: {
    id: string;
    required_points: number;
  };
}

interface MessageStore {
  messages: Message[];
  currentRelationship: Relationship | null;
  isTyping: boolean;
  setIsTyping: (isTyping: boolean) => void;
  addMessage: (message: Message) => void;
  clearMessages: () => void;
  setCurrentRelationship: (relationship: Relationship) => void;
}

interface MessageState {
  messages: Message[];
  currentRelationship: Relationship | null;
  isTyping: boolean;
  setIsTyping: (isTyping: boolean) => void;
  addMessage: (message: Message) => void;
  clearMessages: () => void;
  setCurrentRelationship: (relationship: Relationship) => void;
  isUserTyping: boolean;
  setUserTyping: (isTyping: boolean) => void;
}

export const useMessageStore = create<MessageState>((set) => ({
  messages: [],
  currentRelationship: null,
  isTyping: false,
  setIsTyping: (isTyping: boolean) => set({ isTyping }),
  addMessage: (message) => set((state) => ({ messages: [...state.messages, message] })),
  clearMessages: () => set({ messages: [] }),
  setCurrentRelationship: (relationship) => set({ currentRelationship: relationship }),
  isUserTyping: false,
  setUserTyping: (isTyping: boolean) => set({ isUserTyping: isTyping }),
})); 