import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { colors, StyledInput } from '../styles/globalStyles';
import { useMessageStore } from '../store/messageStore';
import { SendMessage } from '../types';
import { useQueryClient } from '@tanstack/react-query';
import { useWebSocketStore } from '../store/webSocketStore';
import { Message } from '../types/message';

const MessageInput: React.FC = () => {
  const [inputMessage, setInputMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const typingTimeoutRef = useRef<NodeJS.Timeout>();
  const preMessageIntervalRef = useRef<NodeJS.Timeout>();
  const sendMessage = useWebSocketStore((state) => state.sendMessage);
  const addMessage = useMessageStore((state) => state.addMessage);
  const setUserTyping = useMessageStore((state) => state.setUserTyping);

  const queryClient = useQueryClient();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputMessage(e.target.value);

    if (e.target.value.length > 0) {
      setIsTyping(true);
      setUserTyping(true);

      if (!preMessageIntervalRef.current) {
        sendMessage({ type: 'pre_player_message', content: {} });

        preMessageIntervalRef.current = setInterval(() => {
          sendMessage({ type: 'pre_player_message', content: {} });
        }, 5000);
      }
    } else {
      setIsTyping(false);
      setUserTyping(false);
      clearInterval(preMessageIntervalRef.current);
      preMessageIntervalRef.current = undefined;
    }

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      setIsTyping(false);
      setUserTyping(false);
    }, 5000);
  };

  const handleSendMessage = () => {
    if (inputMessage.trim()) {
      console.log('Отправка сообщения');
      const newMessage: Message = {
        content: inputMessage,
        role: 'PLAYER',
        recipient: 'ALL',
        user_id: 1,
        id: Date.now(),
        created_at: new Date().toISOString(),
      };

      const newMessageToSend: SendMessage = {
        type: 'player_message',
        content: {
          content: inputMessage,
        },
      };

      queryClient.setQueryData(['messages'], (oldMessages: Message[] = []) => [...oldMessages, newMessage]);
      addMessage(newMessage);
      sendMessage(newMessageToSend);
      setInputMessage('');
      setIsTyping(false);
      setUserTyping(false);
      clearInterval(preMessageIntervalRef.current);
      preMessageIntervalRef.current = undefined;
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleSendMessage();
  };

  useEffect(() => {
    return () => {
      clearInterval(preMessageIntervalRef.current);
    };
  }, []);

  return (
    <InputContainer>
      <InputWrapper onSubmit={handleSubmit}>
        <StyledInput
          value={inputMessage}
          onChange={handleInputChange}
          placeholder="Input"
        />
        <SendButton type="submit">Send</SendButton>
      </InputWrapper>
    </InputContainer>
  );
};

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  flex-shrink: 0;
  z-index: 3;
`;

const InputWrapper = styled.form`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const SendButton = styled.button`
  background: ${colors.primary};

  height: 100%;
font-family: 'K2D';
font-style: normal;
font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  color: #000000;
  font-weight: 500;
  font-size: 14px;
  padding: 6px 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background: ${colors.primary};
  }
`;

export default MessageInput;
