import React, { useState } from 'react';
import { useUserStore } from '../store/userStore';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { loginAsGuestAPI } from '../services/API';
import { colors, CtaButton, StyledInput } from '../styles/globalStyles';
import logoIcon from '../assets/icons/logo-icon.svg';

const LoginForm: React.FC = () => {
  const setToken = useUserStore((state) => state.setToken);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleGuestLogin = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await loginAsGuestAPI();

      if (response.access_token) {
        setToken(response.access_token);
        navigate('/chat');
      } else {
        // не получилось получить доступ в храм
        setError("Can't get access to the temple. Please try again later.");
      }
    } catch (error) {
      console.error('Error during login:', error);
      setError("Something went wrong. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FullScreenContainer>
      <FormContainer>
        <WelcomeText>Welcome To The Temple</WelcomeText>
        <LogoContainer>
          <Logo src={logoIcon} alt="Logo" />
          <LogoText>OVERSEER</LogoText>
        </LogoContainer>
        <MATermsText>Acceptance of MA Terms</MATermsText>
        <MATermsDescription>
          By using the MAF, you agree that you have read in full, understood and accepted to be legally bound by the terms contained herein, in addition to Telegram's Terms of Service, Telegram's Privacy Policy and the respective Terms of Service of each MA you access.
        </MATermsDescription>

        {error && <ErrorMessage>{error}</ErrorMessage>}
        <CtaButton onClick={handleGuestLogin} disabled={isLoading}>
          {isLoading ? 'Opening the door...' : 'Visit as guest'}
        </CtaButton>
      </FormContainer>
    </FullScreenContainer>
  );
};

const FullScreenContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: ${colors.background};
  padding: 0 10px;
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const InputGroup = styled.div`
  margin-bottom: 20px;
`;

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  color: ${colors.text};
  font-weight: bold;
`;

const ErrorMessage = styled.span`
  color: ${colors.primary};
  font-size: 14px;
  margin-top: 5px;
  display: block;
  text-align: center;
  margin-bottom: 15px;
`;

const WelcomeText = styled.div`
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 10px;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const Logo = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 10px;
`;

const LogoText = styled.span`
  font-family: 'K2D';
  font-style: normal;
  font-weight: 700;
  font-size: 24.6209px;
  line-height: 120%;
  leading-trim: both;
  text-edge: cap;
  text-align: center;
  letter-spacing: 0.39em;
  text-transform: uppercase;
  background: linear-gradient(270deg, #EFEBBA 9.88%, #C9A351 131.46%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const MATermsText = styled.div`
  font-family: 'K2D';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  justify-content: center;
  margin-bottom: 20px;
`;

const MATermsDescription = styled.div`
  font-family: 'K2D';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #808080;
  margin-bottom: 20px;
  padding: 0 20px;
`;

export default LoginForm;