// src/GlobalStyles.ts
import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';

export const colors = {
    background: '#0A0A0A',           // Глубокий темный фон
    backgroundSecondary: '#2D2D2D',  // Чуть светлее основного фона
    padding: 'var(--spacing-small)',
    border: '#382F1D',              // Мягкая граница
    text: '#FFFFFF',               // Светло-серый для текста
    textSecondary: '#A0A0A0',      // Приглушенный текст
    primary: '#FFD67E',            // Discord-подобный синий
    secondary: '#B39DDB',          // Нежный фиолетовый
    accent: '#64FFDA',             // Бирюзовый акцент
    success: '#4CAF50',            // Приглушенный зеленый
    error: '#EF5350',              // Мягкий красный
    warning: '#FFB74D',            // Приглушенный оранжевый
}


const GlobalStyles = createGlobalStyle`

    *, *::before, *::after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }


    html, body, #root {
        height: 100%;
        font-family: "K2D", sans-serif;
        font-weight: 400;
        background-color: ${colors.background};
        overscroll-behavior: none;
        overflow-y: hidden;
        color: ${colors.text};
    }

    /* Additional global styles */
    body {
        line-height: 1.5;
        -webkit-font-smoothing: antialiased;
    }



    input, button, textarea, select {
        font: inherit;
    }

    p, h1, h2, h3, h4, h5, h6 {
        overflow-wrap: break-word;
    }

    a {
        color: inherit; /* Наследует цвет текта родителя */
        text-decoration: none; /* Убирает подчеривание */
        a:visited {
            color: black; /* Устанавливает цвет для посещенных ссылок */
        }
    }

    #root {
        isolation: isolate;
    }

    :root {
        --spacing-small: 8px;
        --spacing-medium: 16px;
        --spacing-large: 24px;
        --border-radius: 1vh;
    }

    /* Стили для уведомлений */
    .Toastify__toast-container {
        z-index: 9999;
    }

    .Toastify__toast {
        font-size: 15px;
        line-height: 1.5;
        font-weight: 600;
        cursor: pointer;
        transition: transform 0.2s ease;
        
        &:hover {
            transform: translateY(-2px);
        }
        background: ${colors.backgroundSecondary} !important;
        color: ${colors.text};
        border-radius: 8px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    }

    .Toastify__toast--success {
        border-left: 6px solid ${colors.success} !important;
    }

    .Toastify__toast--error {
        border-left: 6px solid ${colors.error} !important;
    }

    .Toastify__toast--warning {
        border-left: 6px solid ${colors.warning} !important;
    }

    .Toastify__toast--info {
        border-left: 6px solid ${colors.secondary} !important;
    }

    .Toastify__progress-bar {
        background: linear-gradient(to right, ${colors.primary}, ${colors.accent});
        height: 4px;
    }

    .Toastify__close-button {
        color: #5d4037;
        opacity: 0.7;
        
        &:hover {
            opacity: 1;
        }
    }

    .Toastify__toast-icon {
        margin-right: 12px;
        color: #5d4037;
    }

    .Toastify__toast-body {
        font-family: 'Inter', sans-serif;
        padding: 4px 8px;
    }
`;


export default GlobalStyles;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-small);
`;

export const FlexContainer = styled.div`
  display: flex;
  gap: var(--spacing-medium);
`;

export const GlobalUtilityClasses = createGlobalStyle`
  .m-1 { margin: var(--spacing-small); }
  .m-2 { margin: var(--spacing-medium); }
  .m-3 { margin: var(--spacing-large); }

  .p-1 { padding: var(--spacing-small); }
  .p-2 { padding: var(--spacing-medium); }
  .p-3 { padding: var(--spacing-large); }
`;

export const CtaButton = styled.button<{ disabled?: boolean }>`
  background: #FFFFFF;
  border: 1px solid #382F1D;
  border-radius: 8px;
  padding: 12px;
  width: 100%;
  color: #000000;



font-family: 'K2D';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 18px;
  text-align: center;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => props.disabled ? 0.25 : 1};
  transition: all 0.3s ease;

  &:hover:not(:disabled) {
    background: #F5F5F5;
  }

  &:active:not(:disabled) {
    transform: translateY(1px);
  }
`;

export const StyledInput = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid ${colors.border};
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s;
  background: #000000;
  color: ${colors.text};

  &:focus {
    border-color: ${colors.primary};
    outline: none;
  }
`;
