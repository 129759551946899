import { create } from 'zustand';

import alice from '../assets/images/alice.png';
import bob from '../assets/images/bob.jpg';

interface Character {
  id: number;
  name: string;
  avatarUrl: string;
  missedMessages: number;
}

interface CharacterStore {
  characters: Character[];
  activeCharacterId: number | null;
  setActiveCharacter: (id: number) => void;
  incrementMissedMessages: (id: number) => void;
}

export const useCharacterStore = create<CharacterStore>((set) => ({
  characters: [
    { id: 1, name: 'Alice', avatarUrl: alice, missedMessages: 5 },
    { id: 2, name: 'Bob', avatarUrl: bob, missedMessages: 0 },
    // Добавьте больше персонажей по необходимости
  ],
  activeCharacterId: null,
  setActiveCharacter: (id) => set({ activeCharacterId: id }),
  incrementMissedMessages: (id) => set((state) => ({
    characters: state.characters.map((character) =>
      character.id === id
        ? { ...character, missedMessages: character.missedMessages + 1 }
        : character
    ),
  })),
})); 