import React, { useEffect } from 'react';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import LoginForm from './components/LoginForm';
import SecureRoute from './components/SecureRoute';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ChatPage from './pages/ChatPage';
import GlobalStyles from './styles/globalStyles';
import StoriesListPage from './pages/StoriesListPage';
import StoryPage from './pages/StoryPage';
import '@xyflow/react/dist/style.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyles />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{
          fontSize: '15px',
          fontFamily: "'Inter', sans-serif",
        }}
        toastStyle={{
          background: '#f8f0e3',
          color: '#5d4037',
          borderRadius: '16px',
          boxShadow: '0 8px 24px rgba(0, 0, 0, 0.2)',
          padding: '16px',
          border: '2px solid #d4a373',
        }}
      />
      <Routes>
        <Route path="/login" element={<LoginForm />} />

        <Route element={<SecureRoute />}>
          <Route path="/chat" element={<ChatPage />} />
          <Route path="/story" element={<StoryPage />} />
          <Route path="/story-list" element={<StoriesListPage />} />
          <Route path="/" element={<Navigate to="/chat" replace />} />
        </Route>

      </Routes>

    </QueryClientProvider>
  );
};

export default App;
