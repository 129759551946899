import axios from 'axios';
import { useUserStore } from '../store/userStore';
import { Story } from '../store/storyStore';
import {  Relationship, RelationshipStatus } from '../types';
import { MapResponse, MapRoutesResponse } from '../types/map';
import { Message } from '../types/message';

// const API_BASE_URL = 'http://localhost:3001'; // Изменено на URL прокси-сервера
const API_BASE_URL = process.env.REACT_APP_API_URL_FOR_FRONTEND; // ендпоинт, который будет использовать браузер
// const API_BASE_URL = 'https://cors-anywhere.herokuapp.com/https://cashik-owa-backend-9d68.twc1.net'; // Изменено на URL прокси-сервера

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

// Добавляем перехватчик для установки токена в заголовок
axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

interface InitData {
  auth_date?: number,
  hash?: string,
  query_id?: string,
  user: {
    id: number,
    username: string,
    photo_url?: string
  }
}
export interface GenerateStoryResponse {
  id: number;
  title: string;
  description: string;
  slides: any[];
  created_at: string;
  creation_sec: number;
  viewed: boolean;
  status: string;
}


// Функция для получения сообщений
export const getMessages = async (): Promise<Message[]> => {
  const response = await axiosInstance.get('/messages');
  return response.data.messages;
};



export const sendMessageToAPI = async (message: string) => {
  const response = await axiosInstance.post('/messages', {
    content: message,
  });
  return response.data;
};

export const logoutAPI = async () => {
  const response = await axiosInstance.post('/logout');
  return response.data; // Для консистентности, возвращаем `data`
};

export const loginAPI = async (initData: InitData) => {
  try {
    const response = await axiosInstance.post('/auth/telegram', { initData }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    console.log('Ответ сервера:', response.data);
    return response.data;
  } catch (error) {
    console.error('Ошибка при отправке запроса:', error);
    throw error;
  }
};

export const getStories = async (): Promise<Story[]> => {
  const response = await axiosInstance.get('/stories');
  return response.data;
};


export const getMedia = async () => {
  const response = await axiosInstance.get('/media');
  return response.data;
};



export const generateStory = async (slidesType: string, slidesCount: number, about: string): Promise<GenerateStoryResponse> => {
  const response = await axiosInstance.post('/stories/generate', {
    slides_type: slidesType,
    slides_count: slidesCount,
    about: about
  });
  return response.data;
};

export const getRelationships = async (): Promise<RelationshipStatus[]> => {
  const response = await axiosInstance.get(`/relationships/list`);
  return response.data;
};

export const getCurrentRelationship = async (): Promise<Relationship> => {
  const response = await axiosInstance.get(`/relationships/current`);
  return response.data;
};

export const setRelationshipStatus = async (statusId: string): Promise<void> => {
  const response = await axiosInstance.post(`/relationships/set-status`, { status_id: statusId });
  return response.data;
};

export const completeStory = async (storyId: number): Promise<void> => {
  const response = await axiosInstance.post(`/stories/complete/${storyId}`);
  return response.data;
};

export const fetchMapData = async (): Promise<MapResponse> => {
  const response = await axiosInstance.get('/map');
  return response.data;
};

export const getMapRoutes = async (from: string, to: string): Promise<MapRoutesResponse> => { 
  const response = await axiosInstance.get(`/map/route?start_location_id=${from}&end_location_id=${to}`);
  return response.data;
};

export const deleteAllMessages = async (): Promise<string> => {
  const response = await axiosInstance.post('/messages/delete-all');
  return response.data;
};

export const loginAsGuestAPI = async () => {
  const response = await axiosInstance.post('/auth/guest');
  return response.data;
};
