import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

// Стили и изображения
import { colors } from '../styles/globalStyles';
import avatarIcon from '../assets/images/ai_avatar.png';
import warningIcon from '../assets/icons/error-warning-line.svg';
import arrowDownIcon from '../assets/icons/arrow_right.svg';

// Компоненты
import CircularProgress from '@mui/material/CircularProgress';
import RelationshipStats from './RelationshipStats';
import TypingIndicator from './TypingIndicator';

// Библиотеки и хуки
import { useQuery, useQueryClient } from '@tanstack/react-query';

// API функции
import { getCurrentRelationship, getMessages } from '../services/API';

// Типы и хранилище
import { Message } from '../types/message';
import { useMessageStore } from '../store/messageStore';


const MessageList: React.FC = () => {
  
  const [statsOpen, setStatsOpen] = React.useState(false);
  const messageListRef = useRef<HTMLDivElement | null>(null);
  const isTyping = useMessageStore((state) => state.isTyping);
  const isUserTyping = useMessageStore((state) => state.isUserTyping);
  const [pendingMessage, setPendingMessage] = useState<Message | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const queryClient = useQueryClient();

  const { data: currentRelationship, isLoading: relationshipLoading } = useQuery({
    queryKey: ['currentRelationship'],
    queryFn: getCurrentRelationship,
  });

  const { data: initialMessages, isLoading: messagesLoading, isError: messagesError } = useQuery({
    queryKey: ['messages'],
    queryFn: getMessages,
  });

  useEffect(() => {
    queryClient.setQueryData(['messages'], []);
  }, []);

  const getLevelColor = (statusName: string) => {
    switch (statusName) {
      case 'strangers': return '#FF00FF';
      case 'acquaintances': return '#4B0082';
      case 'friends': return '#00CED1';
      case 'partners': return '#FF7F50';
      default: return '#00CED1';
    }
  };

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTo({
        top: messageListRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [initialMessages, isTyping, isUserTyping]);

  const calculateProgress = () => {
    if (!currentRelationship) return 0;
    const { current_points, current_status } = currentRelationship;
    return (current_points / current_status.required_points) * 100;
  };

  const formatMessage = (content: string) => {
    const emotionRegex = /\*(.*?)\*/;
    const match = content.match(emotionRegex);
    
    if (match) {
      const [fullMatch, emotion] = match;
      const messageWithoutEmotion = content.replace(fullMatch, '').trim();
      
      return (
        <MessageContent>
          <EmotionBlock emotion={emotion}>
            {emotion}
          </EmotionBlock>
          {messageWithoutEmotion ? ` ${messageWithoutEmotion}` : ''}
        </MessageContent>
      );
    }
    
    return content;
  };

  const formatTime = (timestamp: string) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString('ru-RU', { 
      hour: '2-digit', 
      minute: '2-digit'
    });
  };



  if (messagesLoading) return <div>Loading...</div>;
  if (messagesError) return <div>Error fetching messages</div>;

  return (
    <Container>
      <Header>
        <HeaderIcon src={warningIcon} alt="Warning Icon" />
        <HeaderIcon 
          src={arrowDownIcon} 
          alt="Arrow Down Icon" 
          onClick={() => setIsExpanded(!isExpanded)}
          style={{ 
            transform: isExpanded ? 'rotate(0deg)' : 'rotate(180deg)',
            transition: 'transform 0.3s ease'
          }}
        />
      </Header>
      <MessageListWrapper ref={messageListRef} isExpanded={isExpanded}>
        {initialMessages?.filter(msg => msg !== pendingMessage).map((msg, idx) => (
          <MessageContainer key={idx} isUser={msg.role === 'PLAYER'}>
            {/* {msg.role === 'CHARACTER' && (
            //   <AvatarWrapper onClick={() => setStatsOpen(true)}>
            //     <CircularProgress
            //       variant="determinate"
            //       value={100}
            //       style={{
            //         color: currentRelationship
            //           ? `${getLevelColor(currentRelationship.current_status.id)}40`
            //           : '#ccc',
            //         position: 'absolute'
            //       }}
            //       size={50}
            //       thickness={3}
            //     />
            //     <CircularProgress
            //       variant="determinate"
            //       value={calculateProgress()}
            //       style={{
            //         color: currentRelationship
            //           ? getLevelColor(currentRelationship.current_status.id)
            //           : '#00CED1',
            //         position: 'absolute'
            //       }}
            //       size={50}
            //       thickness={3}
            //     />
            //     <Avatar src={avatarIcon} />
            //   </AvatarWrapper>
            // )} */}
            {msg.role === 'SYSTEM' ? (
              <HintMessageItem isDebug={msg.recipient === 'CHARACTER'}>
                {formatMessage(msg.content)}
              </HintMessageItem>
            ) : (
              <MessageItem isUser={msg.role === 'PLAYER'}>
                <MessageHeader>
                  {msg.role === 'PLAYER' ? 'You:' : 'Priest:'}
                </MessageHeader>
                {/* <MessageTime>
                  {formatTime(msg.created_at)}
                </MessageTime> */}
                {formatMessage(msg.content)}
              </MessageItem>
            )}
          </MessageContainer>
        ))}
        {isTyping && (
          <MessageContainer isUser={false}>
            <TypingIndicator who="character" />
          </MessageContainer>
        )}
        {isUserTyping && (
          <MessageContainer isUser={true}>
            <TypingIndicator who="user" />
          </MessageContainer>
        )}
      </MessageListWrapper>

      <RelationshipStats
        open={statsOpen}
        onClose={() => setStatsOpen(false)}
      />
    </Container>
  );
};

const Header = styled.div`
  background: radial-gradient(163.59% 90.02% at 50% 0%, rgba(101, 52, 7, 0.8) 0%, rgba(10, 10, 10, 0.8) 77.42%);
  box-shadow: inset 0px 0px 130px #090911;
  backdrop-filter: blur(4px);
  height: 24px; /* Задайте высоту по вашему усмотрению */
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid ${colors.border};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px; /* Паддинги по бокам 16px, сверху и снизу 0 */
  z-index: 3;
`;

const HeaderIcon = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column; /* Добавлено для вертикального расположения элементов */
  z-index: 3;
`;

const MessageListWrapper = styled.div<{ isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 8px;
  border: 1px solid ${colors.border};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top: none;
  height: ${props => props.isExpanded ? 'calc(100vh - 280px)' : '325px'};
  transition: height 0.3s ease;
  flex-grow: 1;

  background: radial-gradient(163.59% 90.02% at 50% 0%, rgba(101, 52, 7, 0.8) 0%, rgba(10, 10, 10, 0.8) 77.42%);
  box-shadow: inset 0px 0px 130px #090911;
  backdrop-filter: blur(4px);

  /* Стилизация скроллбара */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: padding-box;

    &:hover {
      background-color: rgba(255, 255, 255, 0.9);
    }
  }
`;

const MessageItem = styled.div<{ isUser: boolean }>`
  color: ${(props) => 
    props.isUser ? `${colors.text}` : `${colors.primary}`};
  background-color: ${colors.background};
  padding: 10px 14px;
  border-radius: 16px;
  border: 1px solid ${colors.border};
  max-width: 70%;
  min-width: 60%;
  font-size: 15px;
  line-height: 1.5;
  margin: ${(props) => (props.isUser ? '2px 0 2px 20px' : '2px 20px 2px 0')};
  transition: all 0.2s ease;
  position: relative;
  padding-top: 24px;

  box-sizing: border-box;
  border-radius: 6px;

  &:hover {
    transform: translateY(-1px);
    box-shadow: none;
  }
`;

const MessageHeader = styled.div`
  position: absolute;
  top: 6px;
  left: 14px;
  font-family: 'K2D';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  color: #A7A7A7;
`;

const MessageTime = styled.div`
  position: absolute;
  top: 6px;
  right: 14px;
  font-family: 'K2D';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  color: #A7A7A7;
`;

const MessageContainer = styled.div<{ isUser: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.isUser ? 'flex-end' : 'flex-start')};
  margin: 4px 0;
  gap: 8px;
`;

const AvatarWrapper = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.2s ease;
  margin-right: 4px;

  &:hover {
    transform: scale(1.05);
  }
`;

const Avatar = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 2px solid ${colors.primary}30;
  box-shadow: none;
  transition: all 0.2s ease;

  &:hover {
    border-color: ${colors.primary};
    transform: scale(1.05);
  }
`;

const HintMessageItem = styled.div<{ isDebug?: boolean }>`
  background: linear-gradient(
    145deg,
    ${colors.backgroundSecondary}80,
    ${colors.background}80
  );
  color: ${colors.textSecondary};
  border: 1px dashed ${props => props.isDebug ? colors.warning : colors.border};
  border-radius: 8px;
  padding: 8px 12px;
  max-width: 90%;
  font-size: 13px;
  line-height: 1.4;
  margin: 8px auto;
  text-align: center;
  opacity: 0.9;
  
  &::before {
    content: "${props => props.isDebug ? 'debug hint' : 'hint'}";
    display: block;
    font-size: 11px;
    font-weight: 500;
    margin-bottom: 4px;
    color: ${props => props.isDebug ? colors.warning : `${colors.primary}90`};
    text-transform: uppercase;
    letter-spacing: 1.2px;
  }

  ${props => props.isDebug && `
    background: ${colors.warning}10;
    border-style: dashed;
    border-width: 2px;
    
    &::after {
      content: "👩‍💻";
      display: inline-block;
      margin-left: 6px;
      font-size: 12px;
    }
  `}

  &:hover {
    opacity: 1;
    border-style: solid;
  }
`;

const MessageContent = styled.div`
  display: inline;
  word-wrap: break-word;
  white-space: pre-wrap;
`;

const EmotionBlock = styled.span<{ emotion: string }>`
  font-size: 13px;
  color: ${props => {
    switch (props.emotion.toLowerCase()) {
      case 'smile': return colors.success;
      case 'curious': return colors.secondary;
      case 'surprised': return colors.warning;
      case 'helpful': return colors.primary;
      case 'thinking': return colors.accent;
      default: return colors.text;
    }
  }};
  font-style: italic;
  opacity: 0.9;
  padding-right: 4px;
  font-weight: 500;
  
  &::before {
    content: "•";
    opacity: 0.7;
    margin-right: 4px;
  }
`;

export default MessageList;
