import React from 'react';
import { StoryList } from '../components/StoryList';
import Layout from './Layout';

function StoriesListPage() {
    return (
        <Layout>
            <h1>Stories List</h1>
            <StoryList />
        </Layout>
    );
}

export default StoriesListPage;
