import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStoryStore } from '../store/storyStore';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getStories } from '../services/API';
import { colors } from '../styles/globalStyles';
import { LinearProgress, Button } from '@mui/material';
import { SearchBar } from './SearchBar';
import { LuSwords } from "react-icons/lu";

type TabType = 'active' | 'completed';

export const StoryList: React.FC = () => {
  const { setCurrentStory, addStories } = useStoryStore();
  const navigate = useNavigate();
  const [filteredStories, setFilteredStories] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<TabType>('active');

  const { data: stories, isLoading, error } = useQuery({
    queryKey: ['stories'],
    queryFn: getStories,
  });

  useEffect(() => {
    if (stories) {
      addStories(stories);
      filterStories(stories, '', activeTab);
    }
  }, [stories, addStories, activeTab]);

  const filterStories = (allStories: any[], query: string, tab: TabType) => {
    const filtered = allStories.filter(story =>
      (story.title.toLowerCase().includes(query.toLowerCase()) ||
      story.description.toLowerCase().includes(query.toLowerCase())) &&
      (tab === 'active' ? !story.viewed : story.viewed)
    );
    setFilteredStories(filtered);
  };

  const handleSearch = (query: string) => {
    if (!stories) return;
    filterStories(stories, query, activeTab);
  };

  const handleTabChange = (tab: TabType) => {
    setActiveTab(tab);
    if (stories) {
      filterStories(stories, '', tab);
    }
  };

  const handleStoryClick = (id: number) => {
    setCurrentStory(id);
    navigate('/story');
  };

  return (
    <>
      <SearchBar onSearch={handleSearch} />
      <TabContainer>
        <Tab active={activeTab === 'active'} onClick={() => handleTabChange('active')}>
          Active
        </Tab>
        <Tab active={activeTab === 'completed'} onClick={() => handleTabChange('completed')}>
          Completed
        </Tab>
      </TabContainer>
      <ListContainer>
        {filteredStories.map((story) => (
          <StoryItem key={story.id} onClick={() => handleStoryClick(story.id)}>
            <StoryTitle>{story.title} <LuSwords /></StoryTitle>
            <StoryDescription>
              {story.description}
            </StoryDescription>
            <LinearProgress variant="determinate" value={story.viewed ? 100 : 30} />
          </StoryItem>
        ))}
      </ListContainer>
    </>
  );
};

const ListContainer = styled.div`
  margin-top: 20px;
  border-radius: 8px;
  max-width: 800px;
  width: 100%;
  overflow-y: auto;
`;

const StoryItem = styled.div`
  cursor: pointer;
  border-radius: 4px;
  padding: var(--spacing-small);
  background-color: ${colors.backgroundSecondary};
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: var(--spacing-small);

  &:hover {
    background-color: ${colors.background};
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .MuiLinearProgress-bar {
    background-color: ${colors.primary};
  }
`;

const StoryTitle = styled.div`
  color: ${colors.text};
`;

const StoryDescription = styled.div`
  font-size: 0.9em;
  color: ${colors.secondary};
`;

const TabContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
`;

const Tab = styled.div<{ active: boolean }>`
  padding: 10px 20px;
  cursor: pointer;
  border-bottom: 2px solid ${props => props.active ? colors.primary : colors.border};
  color: ${props => props.active ? colors.primary : colors.text};
  transition: all 0.3s ease;
  text-align: center;
  width: 50%;
`;
