import React from 'react';
import styled from 'styled-components';
import { Dialog, DialogContent, LinearProgress } from '@mui/material';
import { colors } from '../styles/globalStyles';
import { useQuery } from '@tanstack/react-query';
import { getCurrentRelationship } from '../services/API';

interface RelationshipStatsProps {
  open: boolean;
  onClose: () => void;
}

const RelationshipStats: React.FC<RelationshipStatsProps> = ({ open, onClose }) => {
  const { data: relationship } = useQuery({ 
    queryKey: ['currentRelationship'], 
    queryFn: getCurrentRelationship 
  });

  if (!relationship) return null;

  const { current_status, current_points } = relationship;
  const progress = (current_points / current_status.required_points) * 100;

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <StyledDialogContent>
        <Container>
          <LevelCard>
            <StatusTitle>Current Level</StatusTitle>
            <StatusName>{current_status.name}</StatusName>
            <StatusDescription>{current_status.description}</StatusDescription>
          </LevelCard>

          <ProgressSection>
            <ProgressInfo>
              <Points>{current_points} / {current_status.required_points} points</Points>
            </ProgressInfo>
            <StyledProgress variant="determinate" value={progress} />
            {current_status.next_status_id && (
              <ProgressLabel>
                {current_status.required_points - current_points} points needed for next level
              </ProgressLabel>
            )}
          </ProgressSection>
        </Container>
      </StyledDialogContent>
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    background-color: ${colors.background};
    border-radius: 20px;
    padding: 24px;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 0 !important;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const LevelCard = styled.div`
  background: linear-gradient(145deg, ${colors.primary}15, ${colors.primary}25);
  border-radius: 16px;
  padding: 24px;
  text-align: center;
`;

const StatusTitle = styled.div`
  font-size: 14px;
  color: ${colors.text}99;
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const StatusName = styled.div`
  font-size: 28px;
  font-weight: bold;
  color: ${colors.primary};
  margin-bottom: 12px;
`;

const StatusDescription = styled.div`
  font-size: 16px;
  color: ${colors.text};
  line-height: 1.5;
`;

const ProgressSection = styled.div`
  padding: 0 12px;
`;

const ProgressInfo = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
`;

const Points = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${colors.text};
`;

const StyledProgress = styled(LinearProgress)`
  &.MuiLinearProgress-root {
    height: 10px;
    border-radius: 5px;
    background-color: ${colors.text}15;
  }
  
  .MuiLinearProgress-bar {
    background-color: ${colors.primary};
  }
`;

const ProgressLabel = styled.div`
  text-align: center;
  font-size: 14px;
  color: ${colors.text}99;
  margin-top: 8px;
`;

export default RelationshipStats; 