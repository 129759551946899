import React from 'react';
import styled, { keyframes } from 'styled-components';
import { colors } from '../styles/globalStyles';


interface LoaderProps {
  text?: string;
}

export const Loader: React.FC<LoaderProps> = ({ text = 'Загрузка...' }) => {
  return (
    <LoaderContainer>
      <LoaderWrapper>
        <Spinner />
        <LoadingText>{text}</LoadingText>
      </LoaderWrapper>
    </LoaderContainer>
  );
};
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const pulse = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.background};
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Spinner = styled.div`
  width: 50px;
  height: 50px;
  border: 5px solid ${colors.primary};
  border-top: 5px solid ${colors.secondary};
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

const LoadingText = styled.p`
  margin-top: 20px;
  font-size: 18px;
  color: ${colors.text};
  animation: ${pulse} 1.5s ease-in-out infinite;
`;

interface GameLoaderProps {
  text?: string;
}