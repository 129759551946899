import React from 'react';
import styled, { keyframes } from 'styled-components';
import { colors } from '../styles/globalStyles';

interface TypingIndicatorProps {
  who: 'user' | 'character';
}

const TypingIndicator: React.FC<TypingIndicatorProps> = ({ who }) => {
  return (
    <TypingWrapper isUser={who === 'user'}>
      <DotContainer>
        <Dot delay={0} />
        <Dot delay={0.2} />
        <Dot delay={0.4} />
      </DotContainer>
    </TypingWrapper>
  );
};

const bounce = keyframes`
  0%, 100% { transform: translateY(0); opacity: 0.3; }
  50% { transform: translateY(-6px); opacity: 1; }
`;

const TypingWrapper = styled.div<{ isUser: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: ${colors.backgroundSecondary}80;
  border: 1px solid ${colors.border};
  border-radius: 12px;
  width: fit-content;
  align-self: ${props => props.isUser ? 'flex-end' : 'flex-start'};
  margin: ${props => props.isUser ? '0 12px 0 auto' : '0 auto 0 12px'};
  backdrop-filter: blur(8px);
`;

const DotContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  height: 16px;
`;

const Dot = styled.div<{ delay: number }>`
  width: 5px;
  height: 5px;
  background: ${colors.primary};
  border-radius: 50%;
  animation: ${bounce} 1.2s infinite ease-in-out;
  animation-delay: ${props => props.delay}s;
  opacity: 0.3;
`;

export default TypingIndicator; 