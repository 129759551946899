import React from 'react';
import { Slideshow } from '../components/Slideshow';


const StoryPage: React.FC = () => {



    return (
        <Slideshow />
    );
}

export default StoryPage;
