import React, { useEffect } from 'react';
import styled from 'styled-components';
import {ReactTyped} from 'react-typed'; // Убедитесь, что этот импорт правильный
import { colors } from '../styles/globalStyles';

const DescriptionContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${colors.backgroundSecondary}cc;
  color: ${colors.text};
  padding: 15px;
  border-radius: 8px;
  max-width: 80%;
  width: 100%;
  z-index: 10;
  text-align: center;
  max-height: 38vh;
  overflow-y: auto;
`;

interface StoryDescriptionProps {
    text?: string;
    isTypingComplete: boolean;
    setIsTypingComplete: (value: boolean) => void;
}

export const StoryDescription: React.FC<StoryDescriptionProps> = ({ text, isTypingComplete, setIsTypingComplete }) => {
    
    useEffect(() => {
        if (!text) {
            setIsTypingComplete(true); // Если текста нет, считаем, что печатать нечего
        }
    }, [text, setIsTypingComplete]);

    return (
        <DescriptionContainer>
            {isTypingComplete ? (
                <p>{text || 'Нет текста для отображения'}</p>
            ) : (
                text ? (
                    <ReactTyped
                        strings={[text]}
                        typeSpeed={30} // Скорость печати
                        showCursor={false}
                        onComplete={() => setIsTypingComplete(true)} // Обновляем состояние по завершении
                    />
                ) : (
                    <p>Loading...</p>
                )
            )}
        </DescriptionContainer>
    );
};
