import React, { useState } from 'react';
import styled from 'styled-components';
import { useCharacterStore } from '../store/characterStore';
import { colors } from '../styles/globalStyles';

const CharacterTab: React.FC = () => {
  const { characters, activeCharacterId, setActiveCharacter } = useCharacterStore();
  const [isOpen, setIsOpen] = useState(false);

  const toggleTab = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ToggleButton onClick={toggleTab} isOpen={isOpen}>
        <Dots>
          <Dot />
          <Dot />
          <Dot />
        </Dots>
      </ToggleButton>
      <TabContainer isOpen={isOpen}>
        {characters.map((character) => (
          <CharacterItem
            key={character.id}
            onClick={() => setActiveCharacter(character.id)}
          >
            <AvatarWrapper>
              <Avatar
                src={character.avatarUrl}
                alt={character.name}
                isActive={character.id === activeCharacterId}
              />
              {character.missedMessages > 0 && <MissedIndicator />}
            </AvatarWrapper>
            <CharacterName>{character.name}</CharacterName>
          </CharacterItem>
        ))}
      </TabContainer>
    </>
  );
};

const ToggleButton = styled.button<{ isOpen: boolean }>`
  position: fixed;
  left: ${({ isOpen }) => (isOpen ? '60px' : '0')};
  top: 50%;
  transform: translateY(-50%);
  background-color: ${colors.backgroundSecondary};
  border: none;
  width: 20px;
  height: 80px;
  cursor: pointer;
  z-index: 1001;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 4px 4px 0;
  
  &:hover {
    background-color: ${colors.primary}20;
  }
`;

const Dots = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px;
`;

const Dot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${colors.primary};
`;

const TabContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: ${({ isOpen }) => (isOpen ? '0' : '-60px')};
  top: 0;
  bottom: 0;
  width: 60px;
  background-color: ${colors.backgroundSecondary};
  padding: 8px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  transition: left 0.3s;
  z-index: 1000;
`;

const CharacterItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px;
  margin-bottom: 8px;
  cursor: pointer;
  border-radius: 8px;
`;

const AvatarWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Avatar = styled.img<{ isActive: boolean }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 4px;
  border: 2px solid ${({ isActive }) => (isActive ? colors.success : 'transparent')};
  transition: border-color 0.3s;
`;

const MissedIndicator = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  background-color: ${colors.warning};
  border-radius: 50%;
`;

const CharacterName = styled.span`
  color: ${colors.text};
  font-size: 12px;
  text-align: center;
`;

export default CharacterTab; 